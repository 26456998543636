import Vue from 'vue'
import VueI18n from 'vue-i18n'
import axios from 'axios'

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: window.app.login.siteLanguageHrefLang
})

export function loadLanguageAsync() {
  return axios.get(`/typo3conf/ext/theme/Resources/Public/Translations/login/${window.app.login.siteLanguageHrefLang}.json`).then(
      response => {
        i18n.setLocaleMessage(window.app.login.siteLanguageHrefLang, response.data)
      }
  )
}

