<template>
  <div id="login">
    <template v-if="keycloak">
      <template v-if="isAuthenticated">
        <li class="module-nav__item">
          <span class="icon-registration-before">{{username}}</span>
          <div class="overlay">
            <div class="overlay__item">
              <a :href="dashboardurl"><span class="">{{$t('dashboard')}}</span></a>
            </div>
            <div class="overlay__item">
              <a :href="keycloak.createAccountUrl({redirectUri: location})+ '&kc_locale=' + keycloakLocale + '&privacy_url=' + privacyurl"><span class="">{{ $t('account')}}</span></a>
            </div>
            <div class="overlay__item">
              <a :href="keycloak.createLogoutUrl({redirectUri: location})+ '&kc_locale=' + keycloakLocale + '&privacy_url=' + privacyurl"><span class="">{{$t('logout')}}</span></a>
            </div>
          </div>
        </li>
      </template>
      <template v-else>
        <li class="module-nav__item">
          <a :href="createLoginUrl()" v-if="!isAuthenticated && keycloak && typeof keycloak.createLoginUrl === 'function'"><span class="icon-star-before">{{ $t('login')}}</span></a>
        </li>
        <li class="module-nav__item">
          <a :href="createRegisterUrl()" v-if="typeof keycloak.createRegisterUrl === 'function'"><span class="icon-registration-before">{{ $t('register')}}</span></a>
        </li>
      </template>
    </template>
  </div>
</template>

<script>
import Keycloak from 'keycloak-js'
import JsCookie from 'js-cookie'
import { loadLanguageAsync } from "./plugins/i18n";

export default {
  name: 'App',
  data() {
    return {
      keycloakBaseUrl: window.app.login.keycloakBaseUrl,
      keycloakRealm: window.app.login.keycloakRealm,
      keycloakApplicationId: window.app.login.keycloakApplicationId,
      keycloakLocale: window.app.login.keycloakLocale,
      privacyurl: window.app.login.privacylink,
      dashboardurl: window.app.login.dashboardlink,
      keycloak: {},
      location:  window.location.origin + window.location.pathname,
      isAuthenticated: false,
      options: {},
      timeout: 5000,
      username: '',
      interval: ''
    }
  },
  computed: {
    einhellAuthDataOptions: function ()
    {
      let host = '.' + window.app.login.syliusCookieDomain
      return { expires: 7, domain: host, secure: true }
    },
    einhellLoginDataOptions: function ()
    {
      let host = '.' + window.app.login.syliusCookieDomain
      return { expires: 1, domain: host, secure: true }
    }
  },
  methods: {
    createKeycloak: function () {
      this.keycloak = new Keycloak({
        url: this.keycloakBaseUrl,
        realm: this.keycloakRealm,
        clientId: this.keycloakApplicationId
      })
    },
    setOptions: function (data) {
      this.options.token = data.token
      this.options.refreshToken = data.refresh_token
      this.options.idToken = data.id_token
    },
    setUsername: function () {
      this.username = this.keycloak.idTokenParsed.given_name + ' ' + this.keycloak.idTokenParsed.family_name
    },
    writeDataToLocalStorage: function () {
      let ls = window.localStorage
      let newEinhellAuthDataObject = {
        "token": this.keycloak.token,
        "refresh_token": this.keycloak.refreshToken,
        "id_token": this.keycloak.idToken,
        "changed_at":  Math.floor(Date.now() / 1000)
      }
      ls.setItem('einhell-auth-data', JSON.stringify(newEinhellAuthDataObject))
      let cookieObject = {
        "token": this.keycloak.token,
        "changed_at":  Math.floor(Date.now() / 1000)
      }
      let cookieIdTokenObject = {
        "id_token": this.keycloak.idToken
      }
      JsCookie.set('einhell-auth-data', JSON.stringify(cookieObject), this.einhellAuthDataOptions)
      JsCookie.set('einhell-logout-data', JSON.stringify(cookieIdTokenObject), this.einhellAuthDataOptions)

      let newEinhellLoginDataObject = {
        "dashboard_url": this.dashboardurl,
        "privacy_url": this.privacyurl,
        "base_uri": this.location,
        "accountmanager_url": this.keycloak.createAccountUrl({redirectUri: location})+ '&kc_locale=' + this.keycloakLocale + '&privacy_url=' + this.privacyurl
      }
      JsCookie.set('einhell-login-data', JSON.stringify(newEinhellLoginDataObject), this.einhellLoginDataOptions)
    },
    deleteAllData: function () {
      clearInterval(this.interval)
      this.keycloak.clearToken()
      let ls = window.localStorage
      if (ls.getItem('einhell-auth-data')) {
        ls.removeItem('einhell-auth-data')
      }
      if (JsCookie.get('einhell-logout-data')) {
        JsCookie.remove('einhell-logout-data', this.einhellAuthDataOptions)
      }
      if (JsCookie.get('einhell-auth-data')) {
        JsCookie.remove('einhell-auth-data', this.einhellAuthDataOptions)
      }
      if (JsCookie.get('einhell-logout-data')) {
        JsCookie.remove('einhell-logout-data', this.einhellAuthDataOptions)
      }
      if (JsCookie.get('einhell-login-data')) {
        JsCookie.remove('einhell-login-data', this.einhellLoginDataOptions)
      }
      if (JsCookie.get('checkout_loggedin')) {
        JsCookie.remove('checkout_loggedin')
      }
      this.isAuthenticated = false
      ls.setItem('einhell-login-data', JSON.stringify({ loginUrl: this.createLoginUrl() }))
      let newEinhellLoginDataObject = {
        "dashboard_url": this.dashboardurl,
        "privacy_url": this.privacyurl,
        "base_uri": this.location,
      }
      JsCookie.set('einhell-login-data', JSON.stringify(newEinhellLoginDataObject), this.einhellLoginDataOptions)
    },
    deleteAllDataAfterLogout: function () {
      this.deleteAllData()
    },
    checkToken: function () {
      let self = this
      self.keycloak.updateToken(self.timeout / 1000 + 1).then(function (refreshed) {
        if (refreshed) {
          self.writeDataToLocalStorage()
          self.isAuthenticated = refreshed
          if (self.isAuthenticated) {
            window.keycloak.isAuthenticated = true
            self.setUsername()
          }
        }
      }).catch(function() {
        self.deleteAllData()
      })
    },
    initNewInterval: function () {
      let self = this
      self.interval = setInterval(() => {
        self.checkToken()
      }, self.timeout)
    },
    createLoginUrl: function() {
      const loginUrl = this.keycloak.createLoginUrl({redirectUri: this.location})+ '&kc_locale=' + this.keycloakLocale + '&privacy_url=' + this.privacyurl
      window.keycloak.loginUrl = loginUrl
      return loginUrl
    },
    createRegisterUrl: function() {
      const registerUrl = this.keycloak.createRegisterUrl({redirectUri: this.location})+ '&kc_locale=' + this.keycloakLocale + '&privacy_url=' + this.privacyurl
      window.keycloak.registerUrl = registerUrl
      return registerUrl
    },
    init: function () {
      let self = this
      self.createKeycloak()
      self.keycloak.onAuthLogout = self.deleteAllDataAfterLogout

      let einhellAuthData = JSON.parse(window.localStorage.getItem('einhell-auth-data'))
      if (einhellAuthData) {
        self.setOptions(einhellAuthData)
      }

      if (JsCookie.get('checkout_loggedin') && JSON.parse(JsCookie.get('checkout_loggedin')).Loggedin)
      {
        this.options.onLoad = 'check-sso'
        JsCookie.remove('checkout_loggedin', this.einhellAuthDataOptions)
      }

      this.options.checkLoginIframe = false

      self.keycloak.init(self.options).then(function(authenticated) {
        self.isAuthenticated = authenticated
        self.keycloak.onLoad = ''

        if (self.isAuthenticated) {
          window.keycloak.isAuthenticated = true
          window.localStorage.removeItem('einhell-login-data')
          self.writeDataToLocalStorage()
          self.setUsername()
          self.initNewInterval()
        } else {
          self.deleteAllData()
        }

      }).catch(function() {
        self.deleteAllData()
      });
    }
  },
  created() {
    if (!window.keycloak) {
      window.keycloak = {};
    }

    const self = this
    // self.init()
    loadLanguageAsync().then(() => {
      self.init()
    })
  }
}
</script>
